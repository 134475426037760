<template>
    <div>
        <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                
                <a-form-item class="dep" label="群名称:">
                    <a-input v-model="searchForm.nickname" placeholder="群名称"></a-input>
                </a-form-item>
                <a-form-item class="dep" label="群类型:">
                    <a-select v-model="searchForm.room_ext_type"
                            placeholder="请选择"
                            style="width: 120px">
                        <a-select-option value="all">全部</a-select-option>
                        <a-select-option value="0">内部</a-select-option>
                        <a-select-option value="2">外部</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleClickSearchMult">
                        搜索
                    </a-button>    
                </a-form-item>
                <a-form-item>
                    <a-button 
                        icon="reload"
                        @click="handleReload">
                        重置
                    </a-button>    
                </a-form-item>
            </a-form>
        </a-card>
        <a-card>
             <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" icon="plus" @click="handleCreateGroupByLabel">标签建群</a-button>
            </a-row>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record.conversation_id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
                >
                <template slot="qrcode" slot-scope="code">
                    <img :src="code" style="height:50px;width:50px;" @click="preImg(code)" />
                </template>
                <div slot="time" slot-scope="text">
                    {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                </div>
                <div slot="nickname" slot-scope="text">
                    {{text||'临时群'}}
                </div>
                <a-tag  slot="roomtype" slot-scope="text" color="blue">{{text===2?'外部群':'内部群'}}</a-tag>
                <template slot="label" slot-scope="arr">
                        <a-tag v-for="item in arr" :key="item" color="blue" >{{item}}</a-tag>
                </template>
                <template slot="haeremai" slot-scope="arr">
                        <a-tag v-for="item in arr" :key="item" color="blue" >{{item}}</a-tag>
                </template>
                <template slot="operation" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-button type="link" @click="handleGetDetail(record)">详情</a-button>
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                编辑
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a-button type="link" @click="updateName(record)">群名称</a-button>
                                </a-menu-item>
                                <a-menu-item>
                                    <a-button type="link" @click="updateLabel(record)">群标签</a-button>
                                </a-menu-item>
                                <a-menu-item>
                                    <a-button type="link" @click="updateWel(record)">群欢迎语</a-button>
                                </a-menu-item>
                                <a-menu-item>
                                    <a-button type="link" @click="updateRule(record)">群规则</a-button>
                                </a-menu-item>
                                <a-menu-item>
                                    <a-button type="link" @click="updateNotice(record)">群公告</a-button>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                邀请客户
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a-button type="link" @click="listUser(record)">邀请客户</a-button>
                                </a-menu-item>
                                <a-menu-item>
                                    <a-button type="link" @click="listUserTag(record)">邀请标签客户</a-button>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <!-- <a-button type="link" @click="updateLabel(record)">编辑群标签</a-button>
                        <a-button type="link" @click="updateWel(record)">编辑群欢迎语</a-button> -->
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="isPre" title="预览" width="300px" :footer="null" style="text-align:center;">
           <img :src="codeImg" style="width:200px;height:200px;">
            <a-button type="primary" style="margin-top:20px" @click="loadImg(codeImg)">下载</a-button>
        </a-modal>
        <a-modal v-model="addWel" title="欢迎语" width="700px" @ok="handleSubmitWel">
            <a-form>
                <a-form-item 
                    label="群名称" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 12 }"
                    >
                    <a-input
                        v-model="multObj.nickname"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="欢迎语"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }"
                    >
                    <a-row>
                        <a-button type="primary" size="small" style="font-size:12px" @click="handleAddClick('wel')">新增</a-button>
                    </a-row>
                    <a-table
                        :columns="settingColumns" 
                        :data-source="welList"
                        :row-key="record => record._id"
                        :pagination="false"
                        size="small"
                        :bordered="false"
                    >
                    <a-tag slot="type" slot-scope="text" color="blue">{{formatType(text)}}</a-tag>
                    <template slot="content" slot-scope="text,record">
                        <div v-if="record.type=='sendText'">{{text}}</div>
                        <img v-if="record.type=='sendImage'" :src="text" style="width:30px;height:30px" />
                        <video v-if="record.type=='sendVideo'" :src="text" style="width:30px;height:30px"></video>
                        <div v-if="record.type=='sendLink'">
                            <img  :src="record.content.image_url" :title="record.content.content" style="width:30px;height:30px" />
                        </div>
                        <div v-if="record.type=='sendApp'">{{record.content.title}}</div>
                    </template>
                    <template slot="operation" slot-scope="text,record,index">
                        <div class="operation-wrapper">
                            <a-button type="link" style="color:#ff4d4f;" @click="handleDeleteOne('welList',index)">删除</a-button>
                        </div>  
                    </template>
                    </a-table>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="addRule" title="群规则" width="600px" @ok="handleSubmitRule">
            <a-form>
                <a-form-item 
                    label="群名称" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 12 }"
                    >
                   <a-input
                        v-model="ruleObj.nickname"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="最大触发次数" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 12 }"
                    >
                    <a-input v-model="ruleObj.rule_max" :min="1" type="number"/>
                </a-form-item>
                <a-form-item 
                    label="群规则"
                    :label-col="{span:4}"
                    >
                    <a-row>
                        <a-button type="primary" size="small" style="font-size:12px" @click="handleAddRule">新增</a-button>
                    </a-row>
                    <a-table
                        :columns="ruleColumns" 
                        :data-source="ruleList"
                        :row-key="record => record._id"
                        :pagination="false"
                        size="small"
                        :bordered="false"
                    >
                        <template slot="word" slot-scope="text,record">
                            <a-input v-model="record.word" />
                        </template>
                        <template slot="content" slot-scope="text,record">
                            <a-input v-model="record.content" />
                        </template>
                        <template slot="level" slot-scope="text,record">
                            <a-select v-model="record.level" style="width: 120px" >
                                <a-select-option :value="0">
                                    警告
                                </a-select-option>
                                <a-select-option :value="1">
                                   警告&移除
                                </a-select-option>
                            </a-select>
                        </template>
                         <template slot="operation" slot-scope="text,record,index">
                            <div class="operation-wrapper">
                                <a-button type="link" style="color:#ff4d4f;" @click="handleDeleteRule(record,index)" :disabled="ruleList.length==1">删除</a-button>
                            </div>  
                        </template>
                    </a-table>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="addLabel" title="标签" @ok="handleSubmitLabel">
            <a-form>
                <a-form-item 
                    label="群名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 12 }"
                    >
                    <a-input
                        v-model="multObj.nickname"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="标签"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-tree-select
                        v-model="arrList"
                        style="width: 100%"
                        :tree-data="tagTree"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        placeholder="请选择"
                        :replaceFields="{title:'name',key:'id',value:'id'}"
                        @change="getSeleted"
                    />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="visible"  :footer="null">
            <div class="tab-view">
                    <div class="tab-item" :class="{'active':activeTab==='sendText'}" @click="handleChangeTab('sendText')">文本</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendImage'}" @click="handleChangeTab('sendImage')">图片</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendVideo'}" @click="handleChangeTab('sendVideo')">视频</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendLink'}" @click="handleChangeTab('sendLink')">链接</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendApp'}" @click="handleChangeTab('sendApp')">小程序</div>
                </div>
                <a-input-search
                    placeholder="标题"
                    enter-button="搜索"
                    size="large"
                    @search="handleClickSearch"
                    />
                <div class="text-view tab-content" v-if="activeTab==='sendText'">
                    <div class="text-item" v-for="(item,index) in resList" :key="index">
                        <div class="text-title">{{item.title}}</div>
                        <div class="text-desc">{{item.content}}</div>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="img-view tab-content" v-if="activeTab==='sendImage'">
                    <div class="img-item" v-for="(item,index) in resList" :key="index">
                        <img :src="item.content" class="img"/>
                        <p class="title">{{item.title}}</p>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="img-view tab-content" v-if="activeTab==='sendVideo'">
                    <div class="img-item" v-for="(item,index) in resList" :key="index">
                        <video :src="item.content" class="img" ></video>
                        <p class="title">{{item.title}}</p>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="link-view tab-content" v-if="activeTab==='sendLink'">
                    <div class="link-item" v-for="(item,index) in resList" :key="index">
                        <div class="title">{{item.content.title}}</div>
                        <div class="content">
                            <div class="desc">{{item.content.desc}}</div>
                            <img :src="item.content.image_url" class="link-img"/>
                        </div>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="app-view tab-content" v-if="activeTab==='sendApp'">
                    <div class="app-item" v-for="(item,index) in resList" :key="index">
                        <div class="title">
                               <img :src="item.content.thumburl" class="logo-img"/>
                               <span class="app-name">{{item.content.title}}</span>
                           </div>
                           <div class="content">
                               <p class="desc">{{item.content.des || '   '}}</p>
                               <img :src="item.content.image" class="main-img"/>
                           </div>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="btn-view">
                    <button class="btn" :disabled="!hasPrev" @click="handleClickPrev">上一页</button>
                    <button class="btn" :disabled="!hasNext" @click="handleClickNext">下一页</button>
                </div>
        </a-modal>
        <a-modal v-model="showDetail" title="详情" :footer="null" width="700px">
            <a-tabs v-model="currentTab" @change="tabChange">
                <a-tab-pane key="1" tab="基本信息">
                    <div class="detail-view">
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">群名称:</a-col>
                            <a-col class="value" :span="14">{{multObj.nickname}}</a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">群主:</a-col>
                            <a-col class="value" :span="14">{{multObj.create_user_name}}</a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">群标签:</a-col>
                            <a-col class="value" :span="14">
                                <a-tag v-for="item in multObj.label_name" :key="item" color="blue">{{item}}</a-tag>
                            </a-col>
                        </a-row>
                        <a-row class="detail-row" v-if="multObj.notice">
                            <a-col class="label" :span="6">群公告:</a-col>
                            <a-col class="value" :span="14">{{multObj.notice}}</a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">创建时间:</a-col>
                            <a-col class="value" :span="14">
                                {{multObj.create_time | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                            </a-col>
                        </a-row>
                        <a-row class="detail-row">
                            <a-col class="label" :span="6">群欢迎语:</a-col>
                            <a-col class="value" :span="14">
                            <a-table
                                    :columns="settingColumns2" 
                                    :data-source="detailWelList"
                                    :row-key="record => record._id"
                                    :pagination="false"
                                    size="small"
                                    :bordered="false"
                                >
                                    <a-tag slot="type" slot-scope="text" color="blue">{{formatType(text)}}</a-tag>
                                    <template slot="content" slot-scope="text,record">
                                        <div v-if="record.type=='sendText'">{{text}}</div>
                                        <img v-if="record.type=='sendImage'" :src="text" style="width:30px;height:30px" />
                                        <video v-if="record.type=='sendVideo'" :src="text" style="width:30px;height:30px"></video>
                                        <div v-if="record.type=='sendLink'">
                                            <img  :src="record.content.image_url" :title="record.content.content" style="width:30px;height:30px" />
                                        </div>
                                        <div v-if="record.type=='sendApp'">{{record.content.title}}</div>
                                    </template>
                                </a-table>
                            </a-col>
                        </a-row>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="群成员">
                    <div class="detail-view">
                        <!-- <a-row style="text-align:right;margin-bottom:20px;">
                            <a-button type="primary" icon="reload" @click="reloadMember" :disabled="reloadBtn">刷新</a-button>
                        </a-row> -->
                         <a-table
                            :columns="memberColumns" 
                            :data-source="memberList"
                            :row-key="record => record._id"
                            :pagination="memberPagination"
                            @change="handleMemberTableChange"
                        >
                           <template slot="time" slot-scope="text,record">{{(text||record.added_time) | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</template>
                           <span slot="sex" slot-scope="text">{{text===1?'男':text===2?'女':'未知'}}</span>
                            <div class="account-view" slot="user" slot-scope="text,record">
                                <a-avatar :src="record.avator_url" />
                                <div class="text">
                                    {{record.name}}
                                </div>
                            </div>
                            <template slot="operation" slot-scope="text,record,index">
                            <div class="operation-wrapper">
                                <a-button type="link" style="color:#ff4d4f;" @click="handleDeleteGroupUser(record,index)">移除</a-button>
                            </div>  
                        </template>
                        </a-table>
                    </div>
                </a-tab-pane>
            </a-tabs>
            
        </a-modal>
        <a-modal v-model="isCreate" title="标签建群" @ok="handleCreateGroup">
             <a-form>
                 <a-form-item 
                    label="群名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 12 }"
                    >
                    <a-input
                        v-model="createForm.nickname"
                    />
                </a-form-item>
                <a-form-item 
                    label="群主" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 12 }"
                    >
                    
                     <robot-selected style="width:100%" :init="isCreate" @handleSelected="robotSelected"></robot-selected>
                </a-form-item>
                <a-form-item 
                    label="选择标签" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 12 }"
                    >
                     <a-tree-select
                        v-model="labelList"
                        style="width: 100%"
                        :tree-data="tagTree"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        placeholder="请选择"
                        :replaceFields="{title:'name',key:'id',value:'id'}"
                        @change="labelSelected"
                    />
                </a-form-item>
             </a-form>
        </a-modal>
        <a-modal v-model="isUpdateName" title="群名称" @ok="handleSubmitName">
            <a-form>
                <a-form-item 
                    label="群名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                   <a-input
                        v-model="multObj.nickname"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="新名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="newName"/>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="isUpdateNotice" title="群公告" @ok="handleSubmitNotice">
            <a-form>
                <a-form-item 
                    label="群名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                   <a-input
                        v-model="multObj.nickname"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="旧公告" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    v-if="multObj.notice"
                    >
                   <a-input
                        type="textarea"
                        v-model="multObj.notice"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="新公告" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input type="textarea" v-model="notice"/>
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal v-model="isListUser" title="邀请客户">
            <div class="detail-view">
                         <a-table
                            :columns="userColumns" 
                            :data-source="userList"
                            :row-key="record => record._id"
                            :pagination="userPagination"
                            @change="handleUserTableChange"
                        >
                           <span slot="sex" slot-scope="text">{{text===1?'男':text===2?'女':'未知'}}</span>
                            <div class="account-view" slot="user" slot-scope="text,record">
                                <a-avatar :src="record.avatar" />
                                <div class="text">
                                    {{record.username}}
                                </div>
                            </div>
                            <template slot="operation" slot-scope="text,record,index">
                            <div class="operation-wrapper">
                                <a-button type="link" style="color:#ff4d4f;" @click="handleSubmitInviteUser(record,index)">邀请入群</a-button>
                            </div>  
                        </template>
                        </a-table>
                    </div>
        </a-modal>
    </div>
</template>
<script>
const columns=[
    {
      title: "二维码",
      dataIndex: "qrcode",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'qrcode' },
    },
    {
      title: "群名称",
      dataIndex: "nickname",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'nickname' },
    },
    {
      title: "群主",
      dataIndex: "create_user_name",
      align: "center",
      ellipsis: true,
    },
    {
      title: "群类型",
      dataIndex: "room_ext_type",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'roomtype' },
    },
    {
      title: "群标签",
      dataIndex: "label_name",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'label' },
    },
    {
      title: "群欢迎语",
      dataIndex: "haeremai_name",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'haeremai' },
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      align: "center",
      scopedSlots: { customRender: 'time' },
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        ellipsis: true,
        scopedSlots: { customRender: "operation" },
    },
]
const settingColumns = [
    {
        title: '类型',
        dataIndex: 'type',
        align:'center',
        scopedSlots: { customRender: "type" }
    },
    {
        title: '内容',
        dataIndex: 'content',
        align:'center',
         scopedSlots: { customRender: "content" }
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
const settingColumns2 = [
    {
        title: '类型',
        dataIndex: 'type',
        align:'center',
        scopedSlots: { customRender: "type" }
    },
    {
        title: '内容',
        dataIndex: 'content',
        align:'center',
         scopedSlots: { customRender: "content" }
    }
]
const memberColumns = [
    {
        title: '成员',
        dataIndex: 'name',
        align:'center',
        scopedSlots: { customRender: "user" }
    },
    {
        title: '性别',
        dataIndex: 'sex',
        align:'center',
         scopedSlots: { customRender: "sex" }
    },
    {
        title: '入群时间',
        dataIndex: 'join_time',
        align:'center',
         scopedSlots: { customRender: "time" }
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
const ruleColumns = [
     {
        title: '关键字',
        dataIndex: 'word',
        align:'center',
        scopedSlots: { customRender: "word" }
    },
    {
        title: '提醒内容',
        dataIndex: 'content',
        align:'center',
        scopedSlots: { customRender: "content" }
    },
    {
        title: '规则等级',
        dataIndex: 'level',
        align:'center',
         scopedSlots: { customRender: "level" }
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
const userColumns = [
    {
        title: '成员',
        dataIndex: 'name',
        align:'center',
        scopedSlots: { customRender: "user" }
    },
    {
        title: '性别',
        dataIndex: 'sex',
        align:'center',
         scopedSlots: { customRender: "sex" }
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
import {downloadImage} from '@/utils/utils'
import {
    mapState
  } from 'vuex'
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
    name:'mult-code',
    data(){
        return{
            SHOW_PARENT,
            reloadBtn:false,
            loading:false,
            isPre:false,
            addWel:false,
            addLabel:false,
            addRule:false,
            visible:false,
            showDetail:false,
            ruleVisible:false,
            isCreate:false,
            isUpdateName:false,
            isUpdateNotice:false,
            isListUser:false,
            settingColumns,
            settingColumns2,
            memberColumns,
            ruleColumns,
            userColumns,
            currentTab:'1',
            welList:[],
            detailWelList:[],
            memberList:[],
            resList:[],
            arrList:[],
            selectList:[],
            labelList:[],
            select_conversation_id:'',
            ruleList:[{_id:1,level:0,word:'',content:''}],
            pageNum:1,
            pageSize:10,
            activeTab:'sendText',
            searchParam:'',
            addType:'',
            newName:'',
            notice:'',
            hasPrev:false,
            hasNext:false,
            multObj:{},
            ruleObj:{
            },
            codeImg:'',
            searchForm:{
                nickname:'',
                room_ext_type:'all'
            },
            createForm:{
                _id:'',
                label_id:[]
            },
            tableData:[
                
            ],
            userList:[],
            pagination: {
                total: 1,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            memberPagination:{
                total: 1,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            userPagination:{
                total: 1,
                current: 1,
                pageSize: 20, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            columns,
            title:''
        }
    },
    computed: {
        ...mapState(['mqttInit','tagTree'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleSearch()
                }
            },
            immediate:true
        }
    },
    mounted(){
        // console.log(this.tagTree)
    },
    methods:{
        reloadMember(){
            this.reloadBtn = true
            setTimeout(()=>{
                this.reloadBtn = false
            },60000)
            setTimeout(()=>{
                this.getMemberList()
            },3000)
            let topic = 'scrm/room/searchMember'
            let payload = {
                msgId:'searchMember',
                data: {
                    "conversation_id":this.multObj.conversation_id
                },
            };
            
            this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                    console.log(data)
                },
                this
            );
        },
        getSeleted(){
            let arr = []
            this.arrList.forEach(item=>{
                let index = this.tagTree.findIndex(obj=>obj.id==item)
                if(index!==-1){
                    this.tagTree[index].children.forEach(k=>arr.push(k.id))
                }else{
                    arr.push(item)
                }
            })
            this.selectList = arr
        },
        labelSelected(){
            let arr = []
            this.labelList.forEach(item=>{
                let index = this.tagTree.findIndex(obj=>obj.id==item)
                if(index!==-1){
                    this.tagTree[index].children.forEach(k=>arr.push(k.id))
                }else{
                    arr.push(item)
                }
            })
            this.createForm.label_id = arr
        },
        handleCreateGroupByLabel(){
            this.labelList = []
            this.createForm = {
                _id:'',
                label_id:[]
            }
            this.isCreate = true
        },
        handleCreateGroup(){
            if(!this.createForm.nickname || !this.createForm._id || this.createForm.label_id.length==0){
                this.$message.warning('请填写完整')
                return
            }
            this.isCreate = false
            this.$message.success('操作成功')
            setTimeout(()=>{
                this.handleSearch()
            },2000)
            let payload = {
                msgId:'createGroupByLabel',
                data:{
                    ...this.createForm
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/createGroupByLabel",
                payload,
                },
                (topic, data) => {
                    console.log(data)
                },
                this
            );
        },
        robotSelected(val,obj){
            this.createForm._id = obj._id
        },
        handleAddRule(){
            this.ruleList.push({
                _id:this.ruleList.length+1,
                level:0,
                word:'',
                content:''
            })
        },
        handleDeleteRule(row,index){
            this.ruleList.splice(index,1)
        },
        handleSubmitNotice(){
            if(!this.notice){
                this.$message.error('公告不允许为空')
                return
            }
            let payload = {
                msgId:'setRoomNotice',
                clientId:this.multObj.pk,
                data:{
                    conversation_id:this.multObj.conversation_id,
                    notice:this.notice
                }
            }
            this.isUpdateNotice = false
            this.$message.success('修改成功')
            setTimeout(()=>{
                this.handleSearch()
            },2000)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/room/setRoomNotice",
                payload,
                },
                (topic, data) => {
                   console.log(data)
                },
                this
            );
        },
        handleSubmitName(){
            if(!this.newName){
                this.$message.error('名称不允许为空')
                return
            }
            let payload = {
                msgId:'editGroup',
                clientId:this.multObj.pk,
                data:{
                    conversation_id:this.multObj.conversation_id,
                    name:this.newName
                }
            }
            this.isUpdateName = false
            this.$message.success('修改成功')
            setTimeout(()=>{
                this.handleSearch()
            },2000)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/room/setRoomName",
                payload,
                },
                (topic, data) => {
                   console.log(data)
                },
                this
            );
        },
        handleSubmitRule(){
            let arr = this.ruleList.filter(item=>!item.word)
            if(arr.length>0){
                this.$message.warning('关键字不能为空')
                return
            }
            let payload = {
                msgId:'editGroup',
                data:{
                    conversation_id:this.ruleObj.conversation_id,
                    rule_max:this.ruleObj.rule_max,
                    rule:this.ruleList
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/editGroup",
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        this.$message.success('添加成功')
                        this.addRule = false
                        this.handleSearch()
                    }
                },
                this
            );
        },
        handleSubmitLabel(){
            let payload = {
                msgId:'addGroupTag',
                data:{
                    conversation_id:this.multObj.conversation_id,
                    label:this.selectList
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxGroupService/addGroupTag",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('添加成功')
                        this.handleClickSearch()
                        this.addLabel = false
                    }
                },
                this
            );
        },
        handleSubmitWel(){
            let payload = {
                msgId:'addGroupWel',
                data:{
                    conversation_id:this.multObj.conversation_id,
                    haeremai:this.welList.map(item=>item._id)
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxGroupService/addGroupWel",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('添加成功')
                        this.handleClickSearch()
                        this.addWel = false
                    }
                },
                this
            );
        },

        handleSubmitInviteUser(record,index){
        console.log(this.selectpk)
        console.log(this.select_conversation_id)
        console.log(record.friend_user_id)
        
        this.$confirm({
              title: '提示',
              content: `是否确认邀请客户入群?`,
              onOk:()=>{
                  let payload = {
                    clientId:this.selectpk,
                    msgId:'addMember',
                    data: {
                        conversation_id:this.select_conversation_id,
                        member_list:[record.friend_user_id]
                    },
                };
                  this.$mqtt.doPublish(
                    {
                    pubTopic: "scrm/room/addMember",
                    payload,
                    },
                    (topic, data) => {
                        if(data.code==200){
                            this.$message.info('删除成功')
                            this.this.userPagination.current = 1
                            this.getUserList()
                        }
                    },
                    this
                );
              }})

        },
        updateNotice(row){
            this.multObj = {...row}
            this.isUpdateNotice = true
        },
        updateName(row){
            this.multObj = {...row}
            this.isUpdateName = true
        },
        updateLabel(row){
            this.arrList = row.label || []
            this.multObj = {...row}
            this.addLabel = true
        },
        updateWel(row){
            this.multObj = {...row}
            this.currentTab = "1"
            if(row.haeremai && row.haeremai.length>0){
                let welPayLoad = {
                    msgId:'searchDetailWel',
                    data: {
                        "id":row.haeremai
                    },
                }
                let welTopic = "scrm/materialMgtService/batchSearch"
                this.$mqtt.doPublish(
                    {
                    pubTopic: welTopic,
                    payload:welPayLoad,
                    },
                    (topic, data) => {
                        if(data.code == 200){
                            this.welList = data.data.res
                        }
                    },
                    this
                );
            }else{
                this.welList = []
            }
            this.addWel = true
        },
        updateRule(row){
            // console.log(row)
            this.ruleObj = {...row}
            if(!this.ruleObj.rule_max){
                this.$set(this.ruleObj,'rule_max',1)
            }
            if(this.ruleObj.rule){
                this.ruleList = this.ruleObj.rule
            }else{
                this.ruleList = [{_id:1,level:0,word:'',content:''}]
            }
            this.addRule = true
        },
        tabChange(val){
            if(val==2){
                this.memberPagination.current = 1
                this.getMemberList()
            }
        },
        handleMemberTableChange(pagination){
            this.memberPagination = pagination
            this.getMemberList()
        },
        handleUserTableChange(pagination){
            this.userPagination = pagination
            this.listUser()
        },
        getMemberList(){
            let topic = 'scrm/wxGroupService/searchGroupMembers'
            let payload = {
                msgId:'searchGroupMembers',
                data: {
                    "conversation_id":this.multObj.conversation_id,
                    "pageSize":this.memberPagination.pageSize,
                    "pageNum":this.memberPagination.current
                },
            };
            this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                    console.log(data)
                    if(data.code==200){
                        this.memberPagination.total = data.data.count
                        this.memberList = data.data.res
                    }
                },
                this
            );
        },

        handleGetDetail(row){
            this.multObj = {...row}
            this.currentTab = "1"
            this.detailWelList = []
            if(row.haeremai && row.haeremai.length>0){
                let welPayLoad = {
                    msgId:'searchDetailWel',
                    data: {
                        "id":row.haeremai
                    },
                }
                let welTopic = "scrm/materialMgtService/batchSearch"
                this.$mqtt.doPublish(
                    {
                    pubTopic: welTopic,
                    payload:welPayLoad,
                    },
                    (topic, data) => {
                        if(data.code == 200){
                            this.detailWelList = data.data.res
                        }
                    },
                    this
                );
            }
            this.showDetail = true
        },
        handleAddClick(type){
            this.addType = type
            this.handleGetMaterial()
            this.visible = true
        },
         //点击上一页
        handleClickPrev(){
            this.pageNum -- 
            this.handleGetMaterial()
        },
         //点击下一页
        handleClickNext(){
            this.pageNum ++ 
            this.handleGetMaterial()
        },
        handleClickSearch(value){
            this.searchParam = value
            this.pageNum = 1
            this.handleGetMaterial()
        },
        handleChangeTab(val){
            this.activeTab = val
            this.pageNum = 1
            this.searchParam = ''
            this.handleGetMaterial()
        },
        handleDeleteOne(key,index){
            this[key].splice(index,1)
        },
        handleDeleteGroupUser(record,index){
            this.$confirm({
              title: '提示',
              content: `是否确认移除该成员?`,
              onOk:()=>{
                  let payload = {
                    clientId:record.pk,
                    msgId:'delMember',
                    data: {
                        conversation_id:record.conversation_id,
                        member_list:[record.user_id]
                    },
                };
                  this.$mqtt.doPublish(
                    {
                    pubTopic: "scrm/room/delMember",
                    payload,
                    },
                    (topic, data) => {
                        if(data.code==200){
                            this.$message.info('删除成功')
                            //this.handleSearchList()
                            this.this.memberPagination.current = 1
                            this.getMemberList()
                        }
                    },
                    this
                );
              }})
        },
         //查询素材
        handleGetMaterial(){
            let topic = 'scrm/materialMgtService/searchMaterialByPage'
            let payload = {
                msgId:'searchMaterialByPage',
                data: {
                    "type":this.activeTab,
                    "title":this.searchParam,
                    "pageNum":this.pageNum,
                    "pageSize":this.pageSize,
                },
            };
             this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                    this.resList = data.data.res
                    let count = data.data.count
                    this.hasPrev = this.pageNum>1
                    this.hasNext = count>this.pageNum*this.pageSize
                },
                this
            );
        },
        handleClickUse(item){
            if(this.addType=='wel'){
                let arr = this.welList.filter(obj=>obj._id===item._id)
                if(arr.length>0){
                    this.$message.warning('禁止多次添加同一个素材')
                    return
                }
                this.welList.push(item)
            }else{
                let arr = this.autoList.filter(obj=>obj._id===item._id)
                if(arr.length>0){
                    this.$message.warning('禁止多次添加同一个素材')
                    return
                }
                this.autoList.push(item)
            }
            this.visible = false
        },
        handleReload(){
            this.searchForm = {
                nickname:'',
                room_ext_type:'all'
            }
        },
        handleClickSearchMult(){
            this.pagination.current = 1
            this.handleSearch()
        },
        handleSearch(){  
            let {nickname,room_ext_type} = this.searchForm
             let payload = {
                msgId:'searchGroupByPage',
                data:{
                    nickname,
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current
                }
            }
            if(room_ext_type!=='all'){
                payload.data.room_ext_type = room_ext_type
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxGroupService/searchGroupByPage",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.tableData = data.data.res
                    this.pagination.total = data.data.count
                },
                this
            );
        },
        listUser(record){  
            this.isListUser = true
            this.select_conversation_id = record.conversation_id
            this.selectpk = record.pk
            let payload = {
                msgId:'searchExternalUserByPage',
                data:{
                    friend_type:'outer',
                    pageSize:this.userPagination.pageSize,
                    pageNum:this.userPagination.current,
                    user_list:[record.create_user_id]
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxExternalUserService/searchExternalUserByPage",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.userList = data.data.res
                    this.userPagination.total = data.data.count
                },
                this
            );
        },
        getQr(id,clientId){
            return new Promise(resolve=>{
                let payload = {
                    msgId:'getQR',
                    clientId,
                    data:{
                        room_conversation_id:id
                    }
                }
                this.$mqtt.doPublish(
                    {
                    pubTopic: "scrm/room/getQR",
                    payload,
                    },
                    (topic, data) => {
                        resolve(data)
                    },
                    this
                );
            })
            
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearch()
        },
        loadImg(){
            downloadImage(this.codeImg,'code.png')
        },
        preImg(img){
            this.codeImg = img
            this.isPre = true
        },
        formatType(type){
            switch(type){
                case 'sendText':
                    return '文本'
                case 'sendImage':
                    return '图片'
                case 'sendVideo':
                    return '视频'
                case 'sendLink':
                    return '链接'
                case 'sendApp':
                    return '小程序'            
            }
        },
    }
}
</script>
<style lang="scss" scoped>
// .detail-view{
    // max-height: 350px;
    // overflow: scroll;
// }
.detail-view::-webkit-scrollbar{display:none;}
.detail-row{
    height: 60px;
    .label{
        text-align: right;
        margin-right: 40px;
    }
}
.account-view{
        display: flex;
        align-items: center;
        justify-content: center;
        .text{
            margin-left: 5px;
        }
    // justify-content: center;
    }
.operation-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tab-view{
        margin-top: 20px;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #999;
        border-bottom: 1px solid #e7e7e7;
        .tab-item{
            height: 50px;
            line-height: 50px;
            cursor: pointer;
        }
        .tab-item.active{
            color: #48A5A1;
            font-weight: bold;
        }
    }
    .tab-content{
        height: calc(100% - 55px - 60px - 35px);
        box-sizing: border-box;
        overflow-y: auto;
    }
    .img-view{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;

    }
    .search-content{
        box-sizing: border-box;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        .icon-search{
            position: absolute;
            // top:22px;
            left:28px;
            color: #999;
        }
        .inp{
            box-sizing: border-box;
            border:0px;
            background-color: #DBD9D8;
            height: 30px;
            width: 200px;
            padding-left: 30px;
            border-radius: 3px;
        }
        .btn{
            background-color: #48A5A1;
            color: #fff;
            font-size: 12px;
            border-radius: 3px;
            border:0px;
            height: 30px;
            line-height: 30px;
            padding: 0px 10px;
            cursor: pointer;
        }
    }
    .btn-view{
        display: flex;
        justify-content: space-around;
        padding-top: 5px;
        .btn{
            border: 0px;
            background-color:#48A5A1;
            color: #fff;
            width: 60px;
            height: 30px;
            border-radius: 3px;
            cursor: pointer;
        }
        .btn:disabled{
            background-color: #eee;
            color:#999;
            cursor: not-allowed;
        }
    }
    .text-item{
        padding: 10px;
        box-sizing: border-box;
        border-bottom:1px solid #efefef;
        position: relative;
        .text-title{
            font-size: 14px;
            color: #333;
            font-weight: bold;
            margin-bottom: 5px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .text-desc{
            font-size: 12px;
            color: #999;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
    }
    .img-item{
        width: 75px;
        height: 95px;
        padding: 5px;
        border: 1px solid #efefef;
        border-radius: 2px;
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;
        .img{
            width: 100%;
            height: 62px;
        }
        .title{
            font-size: 12px;
            text-align: center;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
    }
    .img-item:nth-child(3n){
        margin-right: 0px;
    }
    .link-view{
        display: flex;
       flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;
    }
    .link-item{
        width: 200px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
        .title{
            // font-size: 16px;
            color: #333;
            height: 42px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            // line-height: 30px;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
        .content{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .desc{
                box-sizing: border-box;
               height: 50px;
               width: 140px; 
               font-size: 12px;
               color: #999;
               display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                word-break: break-all;
                word-wrap: break-word;
                overflow: hidden;
            }
            .link-img{
                width: 50px;
                height: 50px;
            }
        }
    }
    .app-view{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;
    }
    .app-item{
        width: 200px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
       
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
       .title{
            height: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            .logo-img{
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .app-name{
                font-size: 13px;
                color:#999;
                padding-left: 10px;
            }
        }
        .content{
            .desc{
                color: #333;
                font-size: 13px;
                height: 19px;
            }
            .main-img{
                width: 100%;
                height: 100px;
            }
        }
    }
</style>